<template>
  <form :class="{ 'no-padding': !!mail }" @submit.prevent="handleSubmit">
    <div class="control">
      <VueSelect
        :modelValue="selectedCompany"
        class="select"
        placeholder
        search-placeholder
        searchable
        :options="companiesOptions"
        :visibleOptions="companiesOptions"
        :loading="optionsLoading"
        label-by="label"
        value-by="value"
        clear-on-select
        close-on-select
        @input="onSelectChange"
        @update:modelValue="onSelectCompany"
      >
        <template #dropdown-item="{ option }">
          <div>
            <p class="dropdown-item-label">{{ option.label }}</p>
            <p class="dropdown-item-description">{{ option.description }}</p>
            <p class="dropdown-item-description" v-if="option.additional">{{ option.additional }}</p>
          </div>
        </template>
      </VueSelect>

      <label for="companyName">Nom commercial</label>
    </div>
    <div v-if="!mail" class="control">
      <input :value="formData.email" name="email" type="email" @change="onChange" required />
      <label for="Email">Email</label>
    </div>
    <div v-if="!mail" class="control">
      <input type="text" :value="formData.firstName" name="firstName" @change="onChange" required />
      <label for="firstName">Prénom</label>
    </div>
    <div v-if="!mail" class="control">
      <input type="text" :value="formData.lastName" name="lastName" @change="onChange" required />
      <label for="lastName">Nom</label>
    </div>
    <div class="control">
      <input
        :value="formData.phoneNumber"
        name="phoneNumber"
        type="text"
        @change="onChange"
        required
      />
      <label for="phoneNumber">N° de téléphone</label>
    </div>
    <div class="control">
      <input :value="formData.street" name="street" type="text" @change="onChange" required />
      <label for="street">Rue et N°</label>
    </div>
    <div class="control">
      <input :value="formData.zipCode" name="zipCode" type="text" @change="onChange" required />
      <label for="zipCode">Code postal</label>
    </div>
    <div class="control">
      <input :value="formData.city" name="city" type="text" @change="onChange" required />
      <label for="city">Ville</label>
    </div>
    <div class="control">
      <select name="country" required @change="onChange">
        <option
          v-for="country in countries"
          :key="country.id"
          :value="country.id"
        >{{ country.text }}</option>
      </select>
      <label for="country">Pays</label>
    </div>
    <div v-if="!vat" class="control">
      <input
        required
        name="vat"
        type="text"
        @change="onChange"
        :value="formData.vat"
        v-cleave="{ delimiters: [' ', '.'], blocks: [2, 4, 3, 3], uppercase: true }"
      />
      <label for="vat">N° de TVA</label>
    </div>

    <template v-if="mustAgreeTerms">
      <a class="link mt-2" target="_blank" :href="pdfLink">Conditions générales de Lowco</a>

      <label for="terms-check" class="lowco-checkbox mt-4">
        J'accepte les conditions générales de Lowco
        <input
          type="checkbox"
          id="terms-check"
          v-model="areTermsAgreed"
        />
        <span class="checkmark"></span>
      </label>
    </template>

    <button
      v-if="!isSubmitted || error"
      class="lowco-button"
      :class="{ 'lowco-button-disabled': isSubmitDisabled }"
      :disabled="isSubmitDisabled"
      type="submit"
    >{{ mail ? 'Créer une entreprise' : "S'enregistrer" }}</button>
  </form>

  <div v-if="isSubmitted && error" class="lowco-textbox lowco-textbox-error">{{ getErrorLabel() }}</div>
</template>
<script>
import {
  defineComponent,
  toRefs,
  ref,
  computed,
  watch,
} from 'vue';
import VueSelect from 'vue-next-select';
import { load } from 'recaptcha-v3';

import useDebounce from '@/composables/useDebounce';
import lowcoApi from '@/api/lowco-api';
import constants from '@/constants/register.constants';
import countries from '@/constants/countries.constants';
import nominatimApi from '@/api/nominatim-api';
import validatorsUtils from '@/utils/validators.utils';

import config from '@/config';

export default defineComponent({
  name: 'RegisterForm',
  emits: ['after-submit'],
  components: {
    VueSelect,
  },
  props: {
    submit: {
      type: Function,
      required: true,
    },
    mail: {
      type: String,
      required: false,
    },
    vat: {
      type: String,
      required: false,
    },
    mustAgreeTerms: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const {
      submit,
      mail,
      vat: vatNumber,
      mustAgreeTerms,
    } = toRefs(props);

    const initialFormData = {
      vat: vatNumber.value || '',
      companyName: '',
      email: mail.value || '',
      street: '',
      zipCode: '',
      city: '',
      country: 'BE',
      phoneNumber: '',
      firstName: '',
      lastName: '',
    };

    const formData = ref(initialFormData);
    const isSubmitted = ref(false);
    const error = ref(null);
    const areTermsAgreed = ref(false);

    const suggestedCompanies = ref([]);
    const optionsLoading = ref(false);
    const selectedCompany = ref(null);

    // eslint-disable-next-line global-require
    const pdfLink = computed(() => require('../../assets/CGVU_Lowco_-_Entreprises.pdf'));
    const companiesOptions = computed(() => {
      if (formData.value.companyName.length < 2) {
        return [];
      }

      const mappedCompanies = suggestedCompanies.value.map((c) => {
        const { street, city, zipCode } = c.address;

        return {
          value: c.companyId,
          label: c.companyName,
          description: `${street} - ${zipCode} ${city}`,
          additional: c.vat,
        };
      });

      return [
        ...mappedCompanies,
        {
          value: null,
          label: formData.value.companyName,
          description: `Créer '${formData.value.companyName}'`,
        },
      ];
    });

    const currentCompany = computed(
      () => suggestedCompanies.value.find((c) => c.companyId === selectedCompany.value),
    );

    const isSubmitDisabled = computed(() => mustAgreeTerms.value && !areTermsAgreed.value);

    const resetForm = () => {
      selectedCompany.value = null;
      formData.value = initialFormData;
    };

    const fetchCompaniesByName = async (query) => {
      try {
        optionsLoading.value = true;
        const result = await lowcoApi.getCompaniesByName(query);

        suggestedCompanies.value = result;
      } catch (err) {
        console.log(err);
      } finally {
        optionsLoading.value = false;
      }
    };

    const debouncedFetchCompanies = useDebounce(fetchCompaniesByName);

    const onChange = (event) => {
      const { name, value } = event.target;

      formData.value = {
        ...formData.value,
        [name]: value,
      };
    };

    const onSelectChange = (event) => {
      const { value } = event.target;

      formData.value = {
        ...formData.value,
        companyName: value,
      };

      if (value.length < 2) {
        resetForm();
        suggestedCompanies.value = [];
        return;
      }

      debouncedFetchCompanies(value);
    };

    const onSelectCompany = (company) => {
      if (!company) {
        return;
      }

      selectedCompany.value = company;
    };

    const getCompanyCoordinates = async (address) => {
      const {
        street,
        zipCode,
        city,
        country,
      } = address;

      try {
        const { data } = await nominatimApi.getCoordinates(`${street} ${zipCode} ${city}`, country);

        return {
          latitude: data[0].lat,
          longitude: data[0].lon,
        };
      } catch (err) {
        console.log(err);
      }

      return null;
    };

    const handleSubmit = async () => {
      isSubmitted.value = true;
      const hasEmptyValue = Object.keys(formData.value).some((key) => !formData.value[key]);

      if (hasEmptyValue) {
        error.value = constants.EMPTY_FIELD;

        return;
      }

      const { vat, country } = formData.value;

      const isVatNumberValid = validatorsUtils.isVATNumberValid(vat, country);

      if (!isVatNumberValid) {
        error.value = constants.VAT_UNVALID;

        return;
      }

      const {
        companyName,
        email,
        password,
        street,
        zipCode,
        city,
        phoneNumber,
        firstName,
        lastName,
      } = formData.value;

      let valueToSubmit = {
        companyId: currentCompany.value?.companyId ?? null,
        vat,
        companyName,
        email,
        password,
        address: {
          street,
          zipCode,
          city,
          country,
        },
        phoneNumber,
        firstName,
        lastName,
      };

      error.value = null;
      try {
        if (!mail.value) {
          const recaptcha = await load(config.recaptchaPublicKey);
          const token = await recaptcha.execute('submit');

          valueToSubmit = {
            ...valueToSubmit,
            token,
          };
        }
      } catch (err) {
        //
      }

      let coords;

      try {
        coords = await getCompanyCoordinates({
          street,
          zipCode,
          city,
          country,
        });
      } catch (err) {
        //
      }

      if (coords) {
        const { latitude, longitude } = coords;

        valueToSubmit = {
          ...valueToSubmit,
          latitude,
          longitude,
        };
      }

      try {
        const company = await submit.value(valueToSubmit);

        emit('after-submit', company.id);
      } catch (err) {
        const apiError = err.response.data.errors[0];
        console.log(constants[apiError.code]);
        error.value = constants[apiError.code] || constants.GENERAL;
      }
    };

    const getErrorLabel = () => {
      switch (error.value) {
        case constants.EMPTY_FIELD:
          return 'Veuillez remplir tous les champs';
        case constants.VAT_UNVALID:
          return "Le numéro de TVA entré n'est pas valide";
        case constants.DuplicateUserName:
          return "L'adresse mail est déjà utilisée";
        default:
          return 'Une erreur générale est survenue, veuillez réssayer plus tard';
      }
    };

    watch(currentCompany, (value) => {
      if (!value) {
        return;
      }

      const {
        companyName,
        email,
        phoneNumber,
        address,
        vat,
      } = value;

      const {
        street,
        zipCode,
        city,
        country,
      } = address;

      formData.value = {
        vat: vatNumber.value || vat || '',
        companyName: companyName || '',
        email: mail.value || email || '',
        street: street || '',
        zipCode: zipCode || '',
        city: city || '',
        country: country || 'BE',
        phoneNumber: phoneNumber || '',
      };
    });

    watch(mail, (value) => {
      if (!value) {
        return;
      }

      formData.value = {
        ...formData.value,
        email: value,
      };
    });

    return {
      areTermsAgreed,
      isSubmitted,
      error,
      countries,
      pdfLink,
      onChange,
      handleSubmit,
      getErrorLabel,
      onSelectChange,
      companiesOptions,
      onSelectCompany,
      optionsLoading,
      selectedCompany,
      formData,
      isSubmitDisabled,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";
@import "../../assets/styles/common/fonts.scss";
@import "../../assets/styles/common/mixins.scss";

@include centeredLayoutWithLogo();
@include centeredLayoutWithLogoForm();

.no-padding {
  padding: 4rem 0 0 0;
}

select,
.select {
  padding: 1rem;
  border: 1px solid $dark-green;
  border-radius: 0;
  width: 100%;

  @include font-text(1.6rem, null, $dark-green);
}

.select {
  padding: 0.6rem;
}

.dropdown-item-label {
  font-weight: bold;
}

.dropdown-item-description {
  font-size: 1.3rem;
}
</style>
