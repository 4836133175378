const GENERAL = 'GENERAL';
const EMPTY_FIELD = 'EMPTY_FIELD';
const VAT_UNVALID = 'VAT_UNVALID';
const DuplicateUserName = 'DuplicateUserName';
const SENT = 'SENT';

export default {
  GENERAL,
  EMPTY_FIELD,
  VAT_UNVALID,
  DuplicateUserName,
  SENT,
};
